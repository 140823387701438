var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"calendar-form","centered":"","no-close-on-backdrop":"","hide-header-close":"","no-close-on-esc":"","size":"lg","title":"Generar Calendario"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100"},[_c('b-button',{staticClass:"float-right ml-1",attrs:{"variant":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(" Guardar ")]),_c('b-button',{staticClass:"float-right",attrs:{"variant":"outline-secondary"},on:{"click":_vm.closeForm}},[_vm._v(" Cerrar ")])],1)]},proxy:true}]),model:{value:(_vm.showForm),callback:function ($$v) {_vm.showForm=$$v},expression:"showForm"}},[_c('validation-observer',{ref:"refFormObserver"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Tipo de atención."}},[_c('validation-provider',{attrs:{"name":"Tipo de Atención","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.attentionTypes,"label":"name","reduce":function (item) { return item.id; },"clearable":false},model:{value:(_vm.form.attentionType),callback:function ($$v) {_vm.$set(_vm.form, "attentionType", $$v)},expression:"form.attentionType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('div',{staticClass:"col-form-label"},[_vm._v("Fecha")])]),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Fecha Desde","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('small',[_vm._v("Desde")]),_c('b-form-input',{attrs:{"type":"date","min":_vm.today,"max":_vm.form.endDate},model:{value:(_vm.form.startDate),callback:function ($$v) {_vm.$set(_vm.form, "startDate", $$v)},expression:"form.startDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Fecha Hasta","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('small',[_vm._v("Hasta")]),_c('b-form-input',{attrs:{"type":"date","min":_vm.form.startDate},model:{value:(_vm.form.endDate),callback:function ($$v) {_vm.$set(_vm.form, "endDate", $$v)},expression:"form.endDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Duración Consulta","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"col-form-label"},[_vm._v("Duración Consulta")]),_c('v-select',{attrs:{"options":[10,15,20,25,30,35,40,45,50,55]},on:{"input":_vm.handlePickerOptions},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.label)+" minutos ")]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.label)+" minutos ")]}}],null,true),model:{value:(_vm.form.duration),callback:function ($$v) {_vm.$set(_vm.form, "duration", $$v)},expression:"form.duration"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('div',{staticClass:"col-form-label"},[_vm._v("Horario de Atención")])]),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Horario Desde","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('small',[_vm._v("Desde")]),_c('time-select',{attrs:{"picker-options":_vm.pickerOptions,"placeholder":"hh:mm"},on:{"change":_vm.handleTimeChange},model:{value:(_vm.form.startTime),callback:function ($$v) {_vm.$set(_vm.form, "startTime", $$v)},expression:"form.startTime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Horario Hasta","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('small',[_vm._v("Hasta")]),_c('time-select',{attrs:{"picker-options":Object.assign({}, _vm.pickerOptions, {minTime: _vm.form.startTime}),"placeholder":"hh:mm"},on:{"change":_vm.handleTimeChange},model:{value:(_vm.form.endTime),callback:function ($$v) {_vm.$set(_vm.form, "endTime", $$v)},expression:"form.endTime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"md":"6"}},_vm._l((_vm.calendarDays),function(day,idx){return _c('b-row',{key:idx,staticClass:"mb-1"},[_c('b-col',[_c('b-form-checkbox',{staticClass:"custom-control-primary",staticStyle:{"margin-top":"7px"},on:{"change":function($event){return _vm.handleTimeDayChange(day)}},model:{value:(day.checked),callback:function ($$v) {_vm.$set(day, "checked", $$v)},expression:"day.checked"}},[_c('small',[_vm._v(_vm._s(day.name))])])],1),_c('b-col',[_c('time-select',{attrs:{"picker-options":_vm.pickerOptions,"placeholder":"Desde","disabled":!day.checked},model:{value:(day.startTime),callback:function ($$v) {_vm.$set(day, "startTime", $$v)},expression:"day.startTime"}})],1),_c('b-col',[_c('time-select',{attrs:{"picker-options":Object.assign({}, _vm.pickerOptions, {minTime: day.startTime}),"placeholder":"Hasta","disabled":!day.checked},model:{value:(day.endTime),callback:function ($$v) {_vm.$set(day, "endTime", $$v)},expression:"day.endTime"}})],1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }